<template>
  <ion-app>
    <ion-router-outlet id="ion-router-outlet-content"></ion-router-outlet>
  </ion-app>
</template>

<script setup lang="ts">
import {
  IonApp, IonRouterOutlet, useIonRouter, loadingController, useBackButton,
} from '@ionic/vue';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { onMounted, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { SplashScreen } from '@capacitor/splash-screen';
import { useAuthStore } from '@/stores/auth';

import { registerPushNotifications, disablePushNotifications } from '@/plugins/push-notifications';
import { connectBroadcast, disconnectBroadcast } from '@/plugins/broadcast';

import { useAppstoreStore } from '@/stores/inapp-purchases';
import { Device } from '@capacitor/device';

const authStore = useAuthStore();
const ionRouter = useIonRouter();

const { token, user, isLoggedIn } = storeToRefs(authStore);

/* TODO test push notification flow */
/* TODO http plugin inject logic cleanup */
/* TODO move user store api methods to regular Api file (try mock  simple and work up to axios instance) */
/* TODO disablePush notifications should send info to server also */

App.addListener('appUrlOpen', async (event: URLOpenListenerEvent) => {
  // Make email verify request if app opened with email verify deep link.
  if (event.url.indexOf('/email/verify/') > -1) {
    const loading = await loadingController.create({ message: 'Confirming e-mail...', translucent: true });
    loading.present();
    const response = await authStore.verifyEmail(event.url);

    if (response.status === 204) {
      try {
        await authStore.loadUser();
        ionRouter.navigate({ name: 'Index' }, 'forward', 'pop');
      } finally {
        loading.dismiss();
      }
    }
  }
});

watch(isLoggedIn, (newValue) => {
  if (newValue) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    connectBroadcast(token.value!, user.value!);
    registerPushNotifications();
  } else {
    disconnectBroadcast();
    disablePushNotifications();
  }
}, { immediate: true });

onMounted(async () => {
  const { platform } = await Device.getInfo();

  if (platform === 'ios') {
    const appStore = useAppstoreStore();

    await appStore.registerProducts(['1month', '3month', '6month']);
    await appStore.handleUnfinishedTransactions();
    await appStore.handleTransactionUpdates();
  }
});

// Android back button handler.
useBackButton(-1, () => {
  if (!ionRouter.canGoBack()) {
    App.exitApp(); // Exit app if no back history.
  }
});

SplashScreen.hide();
</script>
