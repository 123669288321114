import { App } from 'vue';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { toastController, ToastOptions } from '@ionic/vue';
import {
  warningSharp, bugSharp, checkmarkCircleSharp, closeSharp,
} from 'ionicons/icons';
import { pick } from 'lodash';
import { useAuthStore } from '@/stores/auth';

let axiosInstance: AxiosInstance;
declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $http: AxiosInstance,
  }
}

async function openToast(options?: ToastOptions) {
  const toast = await toastController
    .create({
      duration: 3500,
      position: 'top',
      color: 'primary',
      buttons: [
        {
          icon: closeSharp,
          role: 'cancel',
        },
      ],
      ...options,
    });
  return toast.present();
}

export function createHttp(options: AxiosRequestConfig): any {
  axiosInstance = axios.create(options);

  const http = {
    ...axiosInstance,

    install(app: App) {
      // TODO can get rid of this once stores are converted to useHttp()?
      // eslint-disable-next-line no-param-reassign
      app.config.globalProperties.$http = axiosInstance;

      axiosInstance.interceptors.request.use((config) => {
        const authStore = useAuthStore();

        if (authStore.isLoggedIn && config.headers) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `Bearer ${authStore.token}`;
        }
        return config;
      }, undefined);

      axiosInstance.interceptors.response.use((response) => {
        if (response.data.toast) {
          openToast({
            ...pick(response.data.toast, ['message', 'header', 'duration']),
            icon: checkmarkCircleSharp,
          });
        }
        if (response.data.message) {
          openToast({
            message: response.data.message,
            icon: checkmarkCircleSharp,
          });
        }

        return response;
      }, (error) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        if (!error.response || /5[0-9][0-9]/g.test(error.response.status) || error.response.status === 0) {
          openToast({
            message: 'Check your connection and then refresh page.',
            header: 'No internet connection',
            color: 'primary',
            icon: bugSharp,
          });
        } else if (error.response.status === 401) {
          const authStore = useAuthStore();
          authStore.clearData();
          app.config.globalProperties.$router.replace({ name: 'Login' });
        } else if (error.response.status === 403 && error.response.headers['x-mnf-user-banned']) {
          app.config.globalProperties.$router.replace({ name: 'Banned' });
        } else if (/4[0-9][0-9]/g.test(error.response.status) && error.response.data.message) {
          openToast({
            message: error.response.data.message,
            color: 'warning',
            icon: warningSharp,
          });
        }

        return Promise.reject(error);
      });
    },
  };

  return http;
}

export function useHttp(): AxiosInstance {
  return axiosInstance;
}
