import { CurrentUserResource } from '@/types/UserResrouce';
import { defineStore } from 'pinia';
import { Ref, ref, computed } from 'vue';
import { App } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { Preferences } from '@capacitor/preferences';
import { useHttp } from '@/plugins/http';
import { SignInWithApple, SignInWithAppleOptions, SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';

// eslint-disable-next-line import/prefer-default-export
export const useAuthStore = defineStore('auth', () => {
  const $http = useHttp();

  const initilaized = ref(false);

  const token: Ref<string | null> = ref(null);
  const user: Ref<CurrentUserResource | null> = ref(null);

  const isLoggedIn = computed(() => !!token.value);

  async function loadUser() {
    const { data } = await $http.get('user');
    user.value = data.data;

    Preferences.set({ key: 'user', value: JSON.stringify(user.value) });
    // userMeta = data.meta; TODO do we use it even?
    return user;
  }

  /**
   * Load initial token from device memory.
   *
   * We could get rid of this if we figure out how to get async preferences running in main before router.
   */
  async function init() {
    if (initilaized.value === true) return;

    const { value: storedToken } = await Preferences.get({ key: 'token' });
    const { value: storedUser } = await Preferences.get({ key: 'user' });
    const storedUserObj: CurrentUserResource|null = storedUser ? JSON.parse(storedUser) : null;

    token.value = storedToken;
    user.value = storedUserObj;

    try {
      if (storedToken && !storedUserObj) {
        await loadUser(); // Need to await because router depends on user obj and v1.3 -> v1.4 has only token stored
      }
    } catch (e) {
      // Router must go on.
    }

    initilaized.value = true;
  }

  async function auth(type: 'login' | 'signup', data: any) {
    const { data: rData } = await $http.post(type, data);

    token.value = rData.token as string;
    user.value = rData.data;

    await Preferences.set({ key: 'token', value: token.value });
    await Preferences.set({ key: 'user', value: JSON.stringify(user.value) });

    return rData.data;
  }

  async function login(data: any) {
    return auth('login', data);
  }

  async function signup(data: any) {
    return auth('signup', data);
  }

  async function verifyEmail(url: string) {
    return $http.post('email/verify', { url });
  }

  async function clearData() {
    await Preferences.remove({ key: 'token' });
    await Preferences.remove({ key: 'user' });
    token.value = null;
    user.value = null;
  }

  async function logout() {
    try {
      await $http.post('logout');
    } finally {
      await clearData();
    }
  }

  async function deleteAccount() {
    try {
      await $http.delete('user');
    } finally {
      await clearData();
    }
  }

  async function togglePauseAccount() {
    await $http.post('user/pause');
    loadUser();
  }

  async function fbAuth(data: any) {
    let promiseResolve: any;
    let promiseReject: any;

    const result = new Promise((resolve, reject) => {
      promiseResolve = resolve;
      promiseReject = reject;
    });

    const appUrlOpenListener = await App.addListener('appUrlOpen', async (urlOpenEvent) => {
      if (urlOpenEvent.url.startsWith('meetnfly://token')) {
        const urlToken = /access_token=([^&]+)/.exec(urlOpenEvent.url);

        if (urlToken && urlToken[1]) {
          const params = {
            token: urlToken[1],
            device_name: data.device_name,
            uuid: data.uuid,
          };

          try {
            const response = await $http.post('oauth/facebook/signup', params);
            token.value = response.data.token;
            user.value = response.data.data;

            if (token.value && user.value) {
              await Preferences.set({ key: 'token', value: token.value });
              await Preferences.set({ key: 'user', value: JSON.stringify(user.value) });
              promiseResolve(user.value);
            } else {
              promiseReject();
            }
          } catch (error) {
            promiseReject();
          }
        } else {
          promiseReject();
        }

        await Browser.close();
        await appUrlOpenListener.remove();
      }
    });

    await Browser.open({ url: `${$http.defaults.baseURL}oauth/facebook` });

    return result;
  }

  async function appleAuth(data: any) {
    let promiseResolve: any;
    let promiseReject: any;

    const result = new Promise((resolve, reject) => {
      promiseResolve = resolve;
      promiseReject = reject;
    });

    const options: SignInWithAppleOptions = {
      clientId: process.env.APPLE_CLIENT_ID,
      redirectURI: '', // seems useless for now
      scopes: 'email name',
      state: 'authorization_code',
      nonce: 'nonce_is_returned_mayber',
    };

    await SignInWithApple.authorize(options)
      .then(async (signinResponse: SignInWithAppleResponse) => {
        const params = {
          device_name: data.device_name,
          uuid: data.uuid,
          name: signinResponse.response.givenName,
          email: signinResponse.response.email,
          token: signinResponse.response.identityToken,
          authCode: signinResponse.response.authorizationCode,
        };

        try {
          const response = await $http.post('oauth/apple/signup', params);
          token.value = response.data.token;
          user.value = response.data.data;

          if (token.value && user.value) {
            await Preferences.set({ key: 'token', value: token.value });
            await Preferences.set({ key: 'user', value: JSON.stringify(user.value) });
            promiseResolve(user.value);
          } else {
            promiseReject();
          }
        } catch (error) {
          promiseReject();
        }
      })
      .catch((error) => {
        promiseReject();
      });

    return result;
  }

  return {
    isLoggedIn,
    token,
    user,
    logout,
    deleteAccount,
    togglePauseAccount,
    init,
    login,
    signup,
    verifyEmail,
    loadUser,
    fbAuth,
    clearData,
    appleAuth,
  };
});
