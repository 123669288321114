import { ref } from 'vue';
import { useIonRouter } from '@ionic/vue';
import { Device } from '@capacitor/device';
import {
  PushNotifications, PushNotificationSchema, ActionPerformed, Token, DeliveredNotifications,
} from '@capacitor/push-notifications';
import { useHttp } from '@/plugins/http';

const isActive = ref(false); // TODO Differentiate between granted and registered?

function addListeners(platform: string) {
  const $http = useHttp();

  PushNotifications.addListener(
    'registration',
    (token: Token) => { // TODO isActive here instead?
      $http.put('user/notification-token', {
        platform,
        notification_token: token.value,
      });
    },
  );

  PushNotifications.addListener('registrationError', (error: any) => {
    // TODO not sure what else we can do here?
    throw new Error(error);
  });

  PushNotifications.addListener(
    'pushNotificationReceived',
    async (notification: PushNotificationSchema) => {
      // TODO only fired when app is foreground we could show a local notif when messages arrive in non active chat.
      // console.log(`Push received: ${JSON.stringify(notification)}`);
    },
  );

  PushNotifications.addListener(
    'pushNotificationActionPerformed',
    async (action: ActionPerformed) => {
      const ionRouter = useIonRouter();
      const { data } = action.notification;

      if (data['conversation.thread_id']) {
        const conversationThreadId = parseInt(data['conversation.thread_id'], 10);
        ionRouter.push({ name: 'Conversation', params: { id: conversationThreadId } });
      } else if (data['meet.ulid']) {
        ionRouter.push({ name: 'MeetProfile', params: { ulid: data['meet.ulid'] } });
      } else if (data['match.ulid']) {
        ionRouter.push({ name: 'MatchProfile', params: { ulid: data['match.ulid'] } });
      } else if (data['meetnfly.notification'] === 'meets-found') {
        ionRouter.push({ name: 'Meets' });
      }
    },
  );
}

async function registerPushNotifications(): Promise<boolean> {
  const info = await Device.getInfo();

  if (info.platform === 'web') {
    isActive.value = false;
    return false; // TODO implement web push notifications for pwa?
  }

  const { receive } = await PushNotifications.checkPermissions();

  if (receive === 'granted') {
    addListeners(info.platform);
    await PushNotifications.register();
    isActive.value = true;
    return true;
  }
  if (receive === 'denied') {
    isActive.value = false;
    return false; // TODO implement dialog to instruct how to enable push notifications.
  }

  const permissionReq = await PushNotifications.requestPermissions();
  if (permissionReq.receive === 'granted') {
    addListeners(info.platform);
    await PushNotifications.register();
    isActive.value = true;
    return true;
  }
  isActive.value = false;
  return false;
}

/* TODO we need to tell api not to send notifications anymore? */
async function disablePushNotifications() {
  if (!isActive.value) return false;

  await PushNotifications.removeAllListeners();

  return true;
}

async function removeNotificationsByConversationThreadId(threadId: number) {
  if (!isActive.value) return false;

  const { notifications } = await PushNotifications.getDeliveredNotifications();
  const notificationsToRemove:DeliveredNotifications = { notifications: [] };
  let removeAll = false;

  notifications.forEach((notification) => {
    if ('conversation.thread_id' in notification.data) {
      if (threadId === parseInt(notification.data['conversation.thread_id'], 10)) {
        notificationsToRemove.notifications.push(notification);
      }
    } else if ('android.text' in notification.data) {
      // Dirty workaround for Android because of push notification problems in Capacitor.
      // https://github.com/ionic-team/capacitor-plugins/issues/740
      removeAll = true;
    }
  });

  if (notificationsToRemove.notifications.length > 0) {
    await PushNotifications.removeDeliveredNotifications(notificationsToRemove);
  } else if (removeAll) {
    await PushNotifications.removeAllDeliveredNotifications();
  }

  return true;
}

export {
  registerPushNotifications,
  disablePushNotifications,
  removeNotificationsByConversationThreadId,
};
