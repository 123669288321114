import { defineStore } from 'pinia';
import { Package, Subscription } from '@/types/Api';
import { useAppstoreStore, VerificationResult } from '@/stores/inapp-purchases';
import { Device } from '@capacitor/device';

// eslint-disable-next-line import/prefer-default-export
export const useSubStore = defineStore('subscription', {


  state: () => ({
    packages: [] as Package[],
    subscription: {} as Subscription,
    features: [] as string[],
  }),
  actions: {
    async getSubscription() {
      const { platform } = await Device.getInfo();

      const { data } = await this.$http.get('payments');
      this.packages = data.meta.packages;
      this.subscription = data.data;
      this.features = data.meta.package_features;

      if (platform === 'ios') {
        const appStore = useAppstoreStore()

        this.packages = this.packages
          .filter(pckg => appStore.products.has(pckg.appstore_id))
          .map(pckg => ({
            ...pckg,
            displayName: appStore.products.get(pckg.appstore_id)!.displayName,
            displayPrice: appStore.products.get(pckg.appstore_id)!.price,
          }));
      } else {
        this.packages = this.packages
          .map(pckg => ({
            ...pckg,
            displayName: pckg.duration === 1 ? `${pckg.duration} month` : `${pckg.duration} months`,
            displayPrice: `${pckg.price} €`,
          }));
      }
    },

    async purchasePackage(pckg: Package) {
      const { data } = await this.$http.get(`payments/everypay?package=${pckg.name}`);
      return data.data.redirect_url;
    },

    async purchaseAppstoreProduct(verification: VerificationResult) {
      return await this.$http.post(`appstore/purchase`, {jwsRepresentation: verification.jwsRepresentation});
    }
  },
});
