import { defineStore } from 'pinia';
import { has, filter, keyBy } from 'lodash';
import {
  alertController, modalController, actionSheetController, toastController,
} from '@ionic/vue';
import {
  closeCircleSharp, flagSharp, close, informationCircleOutline, checkmarkSharp, mailUnreadOutline
} from 'ionicons/icons'; // eslint-disable-line import/extensions
import AcceptMeetModal from '@/components/AcceptMeetModal.vue';
import MatchResource, { MatchResourceWithConv } from '@/types/MatchResource';
import MeetResource from '@/types/MeetResource';
import { PaginationLinks, MeetsMeta } from '@/types/Api';
import ConversationThreadResource from '@/types/ConversationThreadResource';
import ConversationParticipantResource from '@/types/ConversationParticipantResource';
import ConversationMessageResource from '@/types/ConversationMessageResource';
import { useAuthStore } from '@/stores/auth';

// TODO init app get conversations latest state. (possibly load not latest messages but state around the last_read_at?)
// - IDEA probably not since push messages would pile onto incomplite thread.
// TODO then subscribe and keep in sync
// TODO after disconnect/reconnect cycle start from beginning?

// eslint-disable-next-line import/prefer-default-export
export const useCompatibilitiesStore = defineStore('conversations', {
  state: () => ({
    meetsLoading: false,
    meets: [] as Array<MeetResource>,
    meetsLinks: {} as PaginationLinks,
    meetsMeta: {} as MeetsMeta,

    matchesLoading: false,
    matches: [] as Array<MatchResource>,
  }),

  getters: {
    unseenMeetsCount(state) {
      return filter(state.meets, (meet: MeetResource) => !meet.is_seen).length;
    },

    moreMeetsAvailable(state) {
      return !!state.meetsLinks.next;
    },

    activeMatches(state) {
      return state.matches.filter((match) => !match.is_deleted);
    },

    matchesByUlid(state): { [key: string]: MatchResource; } {
      return keyBy(state.matches, 'ulid');
    },

    // Conversation messages by thread id.
    conversations(state): Record<number, ConversationThreadResource> {
      return state.matches
        .reduce((acc: Record<number, ConversationThreadResource>, match: MatchResource) => {
          if (match.conversation_thread_id && match.conversation_thread) {
            acc[match.conversation_thread_id] = match.conversation_thread;
          }
          return acc;
        }, {});
    },

    unreadConversationsCount() {
      return filter(this.conversations, (thread: ConversationThreadResource) => {
        const selfParticipant = thread.participants
          .find((participant: ConversationParticipantResource) => participant.is_me);
        if (selfParticipant && selfParticipant.last_read_at && selfParticipant.last_read_at < thread.updated_at) {
          return true;
        }
        return false;
      }).length;
    },
  },

  actions: {
    async getPartnerCriteria() {
      const { data } = await this.$http.get('/user/criteria');

      return data;
    },

    async updatePartnerCriteria(criteria: any) {
      const { data } = await this.$http.put('/user/criteria', criteria);

      return data;
    },

    async getMeets(page = 1, append = false, sort: string|null = null) {
      this.meetsLoading = true;

      try {
        const params = {
          page,
          sort: sort ?? this.meetsMeta.sort?.current,
        };

        const { data } = await this.$http.get('/meets', { params });
        const meets = data.data as Array<MeetResource>;

        if (append) {
          this.meets.push(...meets);
        } else {
          this.meets = meets;
        }

        this.meetsLinks = data.links;
        this.meetsMeta = data.meta;
      } finally {
        this.meetsLoading = false;
      }

      return this.meets;
    },

    async getMeetDetails(ulid: string) {
      const params = { detailed: 1 };
      const { data } = await this.$http.get(`/meets/${ulid}/profile`, { params });

      const meet = this.meets.find((m) => m.ulid === ulid);
      if (meet) {
        meet.is_seen = data.data.is_seen;
      }

      return data;
    },

    // TODO refactor to not leak controller dismiss stuff and just return data concerning the outcome
    async acceptMeet(meet: MeetResource) {
      const authStore = useAuthStore();

      const canMessage = !!(authStore.user?.subscribed || authStore.user?.on_trial);
      const canAccept = !!(canMessage || authStore.user?.free_accept_meets_count);

      const cancelCb = () => {
        modalController.dismiss(null, 'cancel');
      };

      const submitCb = async (message?: string) => {
        const response = await this.$http.post(`/meets/${meet.ulid}/accept`, { message });
        this.removeMeet(meet);

        modalController.dismiss({ message, response }, 'submit');
      };

      if (canMessage && canAccept) {
        const modal = await modalController
          .create({
            component: AcceptMeetModal,
            presentingElement: document.getElementById('ion-router-outlet-content') || undefined,
            componentProps: { meet, cancelCb, submitCb },
          });

        await modal.present();
        return modal;
      }

      if (!canMessage && canAccept) {
        const actionSheet = await actionSheetController.create({
          header: `Confirm ${meet.compatible_profile.user.name}`,
          buttons: [
            {
              text: 'Confirm',
              role: 'selected',
              cssClass: '!text-green-500',
              icon: checkmarkSharp,
              handler: () => {
                this.$http.post(`/meets/${meet.ulid}/accept`).then((response) => {
                  this.removeMeet(meet);
                  actionSheet.dismiss({ response }, 'submit');
                });
                return false;
              },
            }, {
              text: 'Confirm and Send Message!',
              role: 'more',
              cssClass: '!text-gray-500',
              icon: mailUnreadOutline,
              handler: () => {
                window.open(`${process.env.VUE_APP_SERVER_URL}/billing?locale=en`);
              },
            }, {
              text: 'Cancel',
              icon: close,
              role: 'cancel',
            },
          ],
        });

        await actionSheet.present();
        return actionSheet;
      }

      const toast = await toastController.create({
        message: `Hey, ${authStore.user?.name}! Do You want to confirm more profiles?`,
        icon: informationCircleOutline,
        color: 'warning',
        duration: 7000,
        buttons: [
          {
            text: 'Click here',
            handler: () => {
              window.open(`${process.env.VUE_APP_SERVER_URL}/billing?locale=en`);
            },
          },
        ],
      });

      await toast.present();
      return toast;
    },

    async declineMeet(meet: MeetResource) {
      const actionSheet = await actionSheetController.create({
        header: `Decline ${meet.compatible_profile.user.name}?`,
        buttons: [
          {
            text: 'Decline',
            icon: closeCircleSharp,
            role: 'destructive',
            cssClass: '!text-red-500',
            handler: async () => {
              await this.$http.post(`/meets/${meet.ulid}/decline`);
              this.removeMeet(meet);
            },
          }, {
            text: 'Cancel',
            icon: close,
            role: 'cancel',
          },
        ],
      });

      await actionSheet.present();
      return actionSheet;
    },

    // TODO maybe use same is_delete logic as matches to avoid possible erros when views with deleted data are exited.
    removeMeet(meet: MeetResource) {
      const meetIndex = this.meets.findIndex((m) => m.ulid === meet.ulid);
      if (meetIndex > -1) {
        this.meets.splice(meetIndex, 1);
      }
    },

    async getMatches() {
      this.matchesLoading = true;
      try {
        const { data } = await this.$http.get('matches');
        this.matches = data.data as Array<MatchResource>;
      } finally {
        this.matchesLoading = false;
      }

      return this.matches;
    },

    async getMatchDetails(ulid: string) {
      const params = { detailed: 1 };
      const { data } = await this.$http.get(`/matches/${ulid}/profile`, { params });

      // TODO how do we want to show unseen for Matches?
      /* const meet = this.meets.find((m) => m.ulid === ulid);
      if (meet) {
        meet.is_seen = data.data.is_seen;
      } */

      return data;
    },

    // TODO this doesn't feel good maybe we should handle conversations by compatiblity ulid and let back end figure out
    // if new conversation has to be created or...?
    async startMatchConversation(match: MatchResource) {
      const { data } = await this.$http.post(`/matches/${match.ulid}/conversation`);

      const matchWithConversation: MatchResourceWithConv = data.data;
      Object.assign(this.matchesByUlid[match.ulid], matchWithConversation);

      return matchWithConversation;
    },

    async unmatchMatch(match: MatchResource) {
      const alert = await alertController
        .create({
          header: `Unmatch ${match.compatible_profile.user.name}?`,
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
            },
            {
              text: 'Unmatch',
              role: 'destructive',
              handler: async () => {
                await this.$http.post(`/matches/${match.ulid}/unmatch`);
                this.markMatchDeleted(match);
              },
            },
          ],
        });
      alert.present();
      return alert;
    },

    async reportMatch(match: MatchResource) {
      const alert = await alertController
        .create({
          header: `Report ${match.compatible_profile.user.name} profile?`,
          message: 'Please describe why you want to report this user.',
          inputs: [
            {
              id: 'reason',
              name: 'reason',
              type: 'textarea',
            },
          ],
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
            },
            {
              text: 'Report',
              role: 'destructive',
              handler: async ({ reason }: any) => {
                await this.$http.post(`/matches/${match.ulid}/report`, { reason });
                this.markMatchDeleted(match);
              },
            },
          ],
        });
      alert.present();
      return alert;
    },

    async openMatchSafetySheet(match: MatchResource, buttons: any = {}) {
      const actionSheet = await actionSheetController.create({
        header: 'Safety',
        buttons: [
          {
            text: buttons?.report?.text ?? `Report ${match.compatible_profile.user.name}'s profile`,
            role: 'destructive',
            icon: flagSharp,
            handler: async () => {
              const alert = await this.reportMatch(match);
              const { role } = await alert.onDidDismiss();
              if (role === 'destructive') {
                actionSheet.dismiss(null, 'report');
              }
            },
          }, {
            text: buttons?.unmatch?.text ?? `Block ${match.compatible_profile.user.name}'s profile`,
            role: 'warning', // Non standard role with style results in .action-sheet-warning class
            icon: closeCircleSharp,
            handler: async () => {
              const alert = await this.unmatchMatch(match);
              const { role } = await alert.onDidDismiss();
              if (role === 'destructive') {
                actionSheet.dismiss(null, 'unmatch');
              }
            },
          }, {
            text: 'Close',
            icon: close,
            role: 'cancel',
          },
        ],
      });

      actionSheet.present();

      return actionSheet;
    },

    markMatchDeleted(match: MatchResource) {
      const matched = this.matches.find((m) => m.ulid === match.ulid);
      if (matched) {
        matched.is_deleted = true;
      }
    },

    // IMPROVE figure out where to but limit on messages count to avoid memory problems and ui scrolling also.
    async receivedMessage(message: ConversationMessageResource) {
      if (!has(this.conversations, message.thread_id)) { // Match didn't have conversation.
        const { data } = await this.$http.get(`conversations/${message.thread_id}/match`);
        const match: MatchResource = data.data;

        if (this.matchesByUlid[match.ulid]) { // Was not even a match at data loading time.
          Object.assign(this.matchesByUlid[match.ulid], match);
        } else {
          this.matches.push(match);
        }
      } else {
        this.conversations[message.thread_id].messages.push(message);
        this.conversations[message.thread_id].updated_at = message.created_at;
      }
    },

    async sendMessage(conversationId: number, msgBody: string) {
      // TODO insert into array right away and later mark as sent for faster experience
      const { data } = await this.$http.post(`/conversations/${conversationId}/messages`, { message: msgBody });
      const message: ConversationMessageResource = data.data;
      const conversation = this.conversations[conversationId];
      const selfParticipant = conversation.participants
        .find((participant: ConversationParticipantResource) => participant.is_me);

      conversation.messages.push(message);
      conversation.updated_at = message.created_at;
      if (selfParticipant) {
        selfParticipant.last_read_at = message.created_at;
      }

      return message;
    },

    async loadPreviousMessages(conversationId: number, limit: number) {
      const params = {
        ...(this.conversations[conversationId].messages.length > 0
          && { before_id: this.conversations[conversationId].messages[0].id }),
        limit,
      };
      const { data } = await this.$http.get(`conversations/${conversationId}/messages`, { params });
      const messages: Array<ConversationMessageResource> = data.data;

      if (messages.length > 0) {
        this.conversations[conversationId].messages.unshift(...messages);
      }

      return messages;
    },

    // TODO do we need this?
    async loadNextMessages(conversationId: number, limit: number, afterId: number, recursive = false) {
      const params = { after_id: afterId, limit };
      const { data } = await this.$http.get(`conversations/${conversationId}/messages`, { params });
      const messages: Array<ConversationMessageResource> = data.data;

      if (messages.length > 0) {
        this.conversations[conversationId].messages.push(...messages);

        if (recursive && messages.length === limit) {
          const lastNewMessage = messages[messages.length - 1];
          this.loadNextMessages(conversationId, limit, lastNewMessage.id, true);
        }
      }
    },

    // TODO do we need this even?
    async updateConversation(conversationId: number) {
      const { data } = await this.$http.get(`conversations/${conversationId}`);
      Object.assign(this.conversations[conversationId], data.data);
      return data.data;
    },
  },
});
