import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import { useAuthStore } from '@/stores/auth';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Index',
    redirect: { name: 'Conversations' },
  }, {
    path: '/auth',
    name: 'Auth',
    component: () => import('@/views/Auth.vue'),
  }, {
    path: '/auth/signup',
    name: 'Signup',
    component: () => import('@/views/Signup.vue'),
  }, {
    path: '/auth/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
  }, {
    path: '/verify',
    name: 'EmailVerify',
    component: () => import('@/views/EmailVerifyView.vue'),
  }, {
    path: '/profile',
    name: 'ProfileCreate',
    component: () => import('@/views/ProfileCreateView.vue'),
  }, {
    path: '/tabs/',
    component: () => import('@/views/Tabs.vue'),
    children: [
      {
        path: '',
        redirect: '/tabs/meets',
      }, {
        path: 'meets',
        name: 'Meets',
        component: () => import('@/views/MeetsTab.vue'),
      }, {
        path: 'conversations',
        name: 'Conversations',
        component: () => import('@/views/ConversationsTab.vue'),
      }, {
        path: 'profile',
        name: 'Profile',
        component: () => import('@/views/ProfileTab.vue'),
      },
    ],
  }, {
    path: '/meets/:ulid',
    name: 'MeetProfile',
    props: (route) => ({ ulid: route.params.ulid }),
    component: () => import('@/views/MeetProfileView.vue'),
  }, {
    path: '/matches/:ulid',
    name: 'MatchProfile',
    props: (route) => ({ ulid: route.params.ulid }),
    component: () => import('@/views/MatchProfileView.vue'),
  }, {
    path: '/criteria',
    name: 'PartnerCriteria',
    component: () => import('@/views/PartnerCriteriaView.vue'),
  }, {
    path: '/conversations/:id(\\d+)',
    name: 'Conversation',
    props: (route) => ({ id: +route.params.id }),
    component: () => import('@/views/ConversationView.vue'),
  }, {
    path: '/banned',
    name: 'Banned',
    component: () => import('@/views/Banned.vue'),
  }, {
    path: '/faq',
    name: 'FAQ',
    component: () => import('@/views/FAQ.vue'),
  }, {
    path: '/settings',
    name: 'Settings',
    component: () => import('@/views/Settings.vue'),
  },
  {
    path: '/payments',
    name: 'Payments',
    component: () => import('@/views/Payments.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// TODO this needs to be refactored. Currently Settings allows access without auth.
const authRoutes = ['Auth', 'Login', 'Signup'];
const unlimitedRoutes = ['FAQ', 'Settings'];

router.beforeEach(async (to) => {
  const authStore = useAuthStore();

  // Have not found any other way to get async token out of store before router starts doing it's thing.
  await authStore.init();

  if (unlimitedRoutes.includes(String(to.name))) {
    return true;
  }

  if (!authStore.isLoggedIn) {
    if (!authRoutes.includes(String(to.name))) {
      return { name: 'Auth', replace: true };
    }

    return true;
  }

  if (!authStore.user?.has_email_verified) {
    if (to.name !== 'EmailVerify') {
      return { name: 'EmailVerify', replace: true };
    }
    return true;
  }

  if (!authStore.user?.has_profile && to.name !== 'ProfileCreate') {
    return { name: 'ProfileCreate', replace: true };
  }

  if (authRoutes.includes(String(to.name))) {
    return { name: 'Index', replace: true };
  }

  return true;
});

export default router;
