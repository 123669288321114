import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { Ref, ref } from 'vue';
import { App } from '@capacitor/app';
import { PluginListenerHandle } from '@capacitor/core';
import { useAuthStore } from '@/stores/auth';
import { useCompatibilitiesStore } from '@/stores/compatibilities';
import { CurrentUserResource } from '@/types/UserResrouce';
import { useHttp } from './http';

const connectionStatus: Ref<string | null> = ref(null);

let echo: Echo | undefined;
let pusher: Pusher | undefined;
let appStateChangeListener: PluginListenerHandle | undefined;

// TODO pusher & echo stuff
// TODO figure out what needs what config (echo vs pusher)
// IMPROVE maybe use straight Pusher without echo middleman?
// IMPROVE or even better Ably native messaging?
const broadcastConf = {
  key: process.env.VUE_APP_PUSHER_APP_KEY,
  wsHost: 'realtime-pusher.ably.io',
  // httpHost: 'realtime-pusher.ably.io', // Can't get xhr urls to work.
  wsPort: 443,
  disableStats: true,
  encrypted: true,
  echoMessages: false,
  authEndpoint: `${process.env.VUE_APP_SERVER_URL}/api/broadcasting/auth`,
  auth: {
    headers: {
      Authorization: '', // Authorization header needed
    },
  },
  enabledTransports: ['wss', 'ws'] as any[],
};

async function disconnectBroadcast() {
  const $http = useHttp();

  await appStateChangeListener?.remove();
  await pusher?.connection.unbind_all();
  await pusher?.disconnect();
  echo = undefined;
  pusher = undefined;
  appStateChangeListener = undefined;
  connectionStatus.value = null;
  delete $http.defaults.headers.common['X-Socket-ID'];
}

// eslint-disable-next-line import/prefer-default-export
function initPusher(token: string) {
  pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, broadcastConf);

  pusher.connection.bind('state_change', (state: any) => {
    connectionStatus.value = state.current;
  });

  pusher.connection.bind('error', (err: any) => {
    throw Error(err);
  });

  broadcastConf.auth.headers.Authorization = `Bearer ${token}`;

  App.addListener('appStateChange', ({ isActive }) => {
    if (!isActive) {
      // Try to do clean disconnect and try to avoid ws errors from running out of reconnects.
      // https://github.com/pusher/pusher-js/issues/467
      pusher?.disconnect();
    } else {
      pusher?.connect();
    }
  }).then((listener) => {
    appStateChangeListener = listener;
  });

  echo = new Echo({
    // TODO move this out into connectBroadcast yes?
    client: pusher,
    ...broadcastConf,
    broadcaster: 'pusher',
  });
}
// TODO should refactor to vue router like pure create function?
// Then again provide/inject results alawys in same instance?
function connectBroadcast(token: string, user: CurrentUserResource) {
  initPusher(token);

  const authStore = useAuthStore();
  const compatibilitiesStore = useCompatibilitiesStore();
  const $http = useHttp();

  echo
    ?.private(`app.user.${user.ulid}`)
    .listen('Conversation\\NewMessage', (event: any) => {
      compatibilitiesStore.receivedMessage(event.message);
    })
    .listen('Compatibilities\\MeetsUpdatedEvent', (event: any) => {
      compatibilitiesStore.getMeets();
    })
    .notification((notification: any) => {
      if (notification.type === 'App\\Notifications\\MeetsFound') {
        compatibilitiesStore.getMeets();
      } else if (notification.type === 'App\\Notifications\\MeetAccepted') {
        // TODO use notification.meet_ulid to get specific update?
        compatibilitiesStore.getMeets();
      } else if (notification.type === 'App\\Notifications\\MatchFound') {
        // TODO use notification.match_ulid to get specific update?
        compatibilitiesStore.getMatches();
      } else if (notification.type === 'App\\Notifications\\ProfileApproved') {
        authStore.loadUser();
      }
    })
    .subscribed(() => {
      // This must remain here so conversations would stay in sync after reconnect.
      // TODO handle this outside by listening connectionStatus or somesing?
      authStore.loadUser();
      if (user.has_email_verified) {
        compatibilitiesStore.getMeets();
        compatibilitiesStore.getMatches();
      }
      if (echo) {
        // TODO make $http interceptor handle this itself?
        $http.defaults.headers.common['X-Socket-ID'] = echo.socketId();
      }
    })
    .error(() => {
      // Try to make sure we load needed data even if subsibe fails for some reason.
      authStore.loadUser();
      if (user.has_email_verified) {
        compatibilitiesStore.getMeets();
        compatibilitiesStore.getMatches();
      }
    });
}

export { connectBroadcast, disconnectBroadcast, connectionStatus };
