<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>{{ `Confirm ${props.meet.compatible_profile.user.name}` }}</ion-title>
      <ion-buttons slot="end">
        <ion-button @click="() => props.cancelCb()">Cancel</ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding" fullscreen>
    <ion-item lines="full" :counter="true" class="shrink-0">
      <ion-label position="stacked">Write a message</ion-label>
      <ion-textarea
        v-model="message"
        placeholder="You can write here what you found interesting and eye-catching about this persons profile."
        rows="4"
        :maxlength="255"
      />
    </ion-item>
    <ion-item lines="none" class="shrink-0">
      <ion-label class="ion-text-wrap">
        {{ `The message will be sent to ${props.meet.compatible_profile.user.name} - it will surely make ${props.meet.compatible_profile.user.name} happy!` }}
      </ion-label>
    </ion-item>
    <ion-button
      class="w-full px-4 mt-auto bottom-4 shrink-0"
      @click="() => props.submitCb(message)"
    >
      Confirm Meet!
    </ion-button>
  </ion-content>
</template>
<script setup lang="ts">
import { defineProps, ref, PropType } from 'vue';
import {
  IonHeader, IonToolbar, IonTitle, IonContent, IonButtons, IonButton, IonItem, IonLabel, IonTextarea,
} from '@ionic/vue';
import MeetResource from '@/types/MeetResource';

const message = ref('');
const props = defineProps({
  meet: { type: Object as PropType<MeetResource>, required: true },
  cancelCb: { type: Function, required: true },
  submitCb: { type: Function, required: true },
});
</script>
<style scoped>
ion-content::part(scroll) {
  display: flex;
  flex-direction: column;
}</style>
