import { defineStore } from 'pinia';
import { StoreKit2 } from '../../cap-plugins/store-kit-2';
import { useSubStore } from '@/stores/subscription';
import { useAuthStore } from '@/stores/auth';


export class VerificationResult {
  private readonly uuid: string;
  jwsRepresentation: string | null = null;

  constructor(uuid: string, data: any) {
    this.uuid = uuid;
    Object.assign(this, data);
  }

  async finish() {
    await StoreKit2.finishTransaction({ uuid: this.uuid });
  }
}

export class Product {
  identifier: string;
  displayName: string = '';
  price: string = '';

  constructor(identifier: string, data: any) {
    this.identifier = identifier;
    Object.assign(this, data);
  }

  async purchase() {
    const response = await StoreKit2.purchaseProduct({ productIdentifier: this.identifier });
    return new VerificationResult(response.verification.uuid, response.verification);
  }
}

// eslint-disable-next-line import/prefer-default-export
export const useAppstoreStore = defineStore('appstore', {
  state: () => ({
    products: new Map() as Map<string, Product>,
  }),
  actions: {
    async registerProducts(products: string[]) {
      await Promise.all(products.map(async (identifier) => {
        const productResponse = await StoreKit2.getProductDetails({ productIdentifier: identifier });
        if (productResponse.responseCode == 0) {
          const product = new Product(identifier, productResponse.data);
          this.products.set(identifier, product);
        }
      }));
    },

    // Sometimes between app loads there might be unfinished transactions for what ever reason (backend failed to process it, etc)
    // This function will try to handle those unfinished transactions
    // https://developer.apple.com/documentation/storekit/transaction/3856631-unfinished
    async handleUnfinishedTransactions() {
      const subStore = useSubStore();
      const authStore = useAuthStore();

      const response = await StoreKit2.getUnfinishedTransactions();

      if (response.response === 'success' && response.data.length > 0) {
        await Promise.all(response.data.map(async (resp) => {
          const verification = new VerificationResult(resp.uuid, resp);
          await subStore.purchaseAppstoreProduct(verification).catch(() => {
            return Promise.resolve();
          });
          await verification.finish();
        }));

        await subStore.getSubscription();
        await authStore.loadUser();

      }

    },

    // Sometimes transactions might happen outside of the app, this is the place to handle those
    // https://developer.apple.com/documentation/storekit/transaction/3851206-updates
    async handleTransactionUpdates() {
      const subStore = useSubStore();
      const authStore = useAuthStore();

      StoreKit2.addListener('transactionUpdated', async (resp) => {
        const verification = new VerificationResult(resp.uuid, resp);

        await subStore.purchaseAppstoreProduct(verification).catch(() => {
          return Promise.resolve();
        });
        await verification.finish();

        await subStore.getSubscription();
        await authStore.loadUser();
      });
    }
  },
});
