import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref, PropType } from 'vue';
import {
  IonHeader, IonToolbar, IonTitle, IonContent, IonButtons, IonButton, IonItem, IonLabel, IonTextarea,
} from '@ionic/vue';
import MeetResource from '@/types/MeetResource';


export default /*@__PURE__*/_defineComponent({
  __name: 'AcceptMeetModal',
  props: {
  meet: { type: Object as PropType<MeetResource>, required: true },
  cancelCb: { type: Function, required: true },
  submitCb: { type: Function, required: true },
},
  setup(__props) {

const message = ref('');
const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(IonHeader), null, {
      default: _withCtx(() => [
        _createVNode(_unref(IonToolbar), null, {
          default: _withCtx(() => [
            _createVNode(_unref(IonTitle), null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(`Confirm ${props.meet.compatible_profile.user.name}`), 1)
              ]),
              _: 1
            }),
            _createVNode(_unref(IonButtons), { slot: "end" }, {
              default: _withCtx(() => [
                _createVNode(_unref(IonButton), {
                  onClick: _cache[0] || (_cache[0] = () => props.cancelCb())
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode("Cancel")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_unref(IonContent), {
      class: "ion-padding",
      fullscreen: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(IonItem), {
          lines: "full",
          counter: true,
          class: "shrink-0"
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(IonLabel), { position: "stacked" }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode("Write a message")
              ])),
              _: 1
            }),
            _createVNode(_unref(IonTextarea), {
              modelValue: message.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((message).value = $event)),
              placeholder: "You can write here what you found interesting and eye-catching about this persons profile.",
              rows: "4",
              maxlength: 255
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_unref(IonItem), {
          lines: "none",
          class: "shrink-0"
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(IonLabel), { class: "ion-text-wrap" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(`The message will be sent to ${props.meet.compatible_profile.user.name} - it will surely make ${props.meet.compatible_profile.user.name} happy!`), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_unref(IonButton), {
          class: "w-full px-4 mt-auto bottom-4 shrink-0",
          onClick: _cache[2] || (_cache[2] = () => props.submitCb(message.value))
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createTextVNode(" Confirm Meet! ")
          ])),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}
}

})